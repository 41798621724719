'use client';

import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

const MetaPixelTracker = () => {
    useEffect(() => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL as string);
        ReactPixel.pageView()
    }, []);
    
    return null;
};

export default MetaPixelTracker;